import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../api';
import Select from 'react-select';
import { mediaUrl } from '../../config';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from 'reactstrap';
// core components

const { Option } = Select;

const EditHospital = ({
  openModal,
  handleModal,
  hospital,
  setHospital,
  getHospitals
}) => {
  const [previewImage, setPreviewImage] = useState(
    hospital?.logo ? mediaUrl + hospital.logo : null
  );
  useEffect(() => {}, []);

  const handleInput = (e) => {
    setHospital({ ...hospital, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setHospital({ ...hospital, logo: selectedImage });

    if (selectedImage) {
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };
  console.log('hospital22', hospital);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (hospital.name == '') {
      return toast.error("Please enter hospital's name");
    }
    if (hospital.logo == null) {
      return toast.error('Please Add Logo');
    }
    if (hospital.email == '') {
      return toast.error('Please enter email');
    }
    if (!(hospital.email.includes('.') && hospital.email.includes('@'))) {
      return toast.error('Please enter a valid email');
    }

    if (hospital.password && hospital.password.length < 8) {
      return toast.error('Passwords should be of atleast 8 letters');
    }

    if (hospital.address == '') {
      return toast.error('Address is required');
    }
    const formData = new FormData();
    console.log('hospital11', hospital);
    Object.entries(hospital).forEach(([key, value]) => {
      formData.append(key, value);
    });

    for (let [key, value] of formData.entries()) {
      console.log('1212edit', key, value);
    }
    api('put', `/v1/hospitals/${hospital._id}`, formData).then((res) => {
      toast.success('hospital edited successfully');
      getHospitals();
      handleModal();
    });
  };
  const handleRemoveImg = () => {
    setPreviewImage(null);
    setHospital({ ...hospital, logo: null });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a hospital
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-logo"
                        style={{ marginLeft: '2%' }}
                      >
                        Hospital Logo
                        {/* <i className="ni ni-album-2" /> */}
                      </label>
                      {!previewImage ? (
                        <input
                          className="form-control-file form"
                          id="input-logo"
                          type="file"
                          onChange={handleImageChange}
                          style={{ marginLeft: '2%' }}
                        />
                      ) : (
                        <div className="previewSelectedImg">
                          <img
                            style={{
                              width: '15%',
                              height: '15%',
                              marginTop: '1rem',
                              marginLeft: '2%'
                            }}
                            onClick={handleRemoveImg}
                            src={previewImage}
                            alt="Preview"
                          />
                        </div>
                      )}
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <span style={{ color: 'red' }}>
                          {hospital.name ? '' : '*'}{' '}
                        </span>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's name here"
                          type="text"
                          value={hospital.name}
                          name="name"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <span style={{ color: 'red' }}>
                          {hospital.email ? '' : '*'}{' '}
                        </span>
                        <label className="form-control-label">Email</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's email here"
                          type="email"
                          value={hospital.email}
                          name="email"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-password"
                        >
                          Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-password"
                          placeholder="Password"
                          type="password"
                          value={hospital.password}
                          disabled
                          name="password"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <span style={{ color: 'red' }}>
                          {hospital.number ? '' : '*'}{' '}
                        </span>
                        <label className="form-control-label">Address</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's contact here"
                          type="text"
                          value={hospital.address}
                          name="address"
                          onChange={handleInput}
                          // maxLength="50"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditHospital;
