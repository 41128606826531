import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import api from 'api';
import moment from 'moment';


import { Button } from 'reactstrap';
const myHeader = ["Student Name", "Hospital Name", "Elective Name",'Start Date','Duration'];

// Component
const ExportElectives = ({  electives }) => {
  const [loading, setLoading] = useState(false);
  // Exporting

  // File Type
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // Extension
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    // Setting cols width
    var wscols = [
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      
      
    ];
    // Sorting

    const ws = XLSX.utils.json_to_sheet(
      csvData,
      { header: myHeader },
      {
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
      }
    );
    ws['!cols'] = wscols;

    const wb = { Sheets: { users: ws }, SheetNames: ['users'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const e_data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(e_data, fileName + fileExtension);
  };

  // exporting excel
  const handleSubmitExcel = async (electives) => {
    setLoading(true);
    console.log("in export",electives)
    if(electives?.length>0){
        console.log("electives for csv",electives)
         const updatedelectives = electives.map((elective) => {
          return {
            "Student Name": elective?.students?.length>0 ? elective?.students?.map((_) => `${_?.studentId?.name ?_?.studentId?.name:''}`).join(', '):'',
            "Hospital Name": elective?.hospital?.name,
            "Elective Name": elective.title,
            "Start Date":moment(elective.start_date).format('MM-DD-YYYY'),
            Duration: elective.duration,
          };
        });
        setLoading(false);
      console.log("Electives for export ", updatedelectives);
      exportToCSV(updatedelectives, 'Electives CSV');
    }
  };

  // Render
  return (
    <div className="d-flex justify-content-between ">
      <h3 className="mb-0"></h3>
      <Button
        color="primary"
        href="#pablo"
        onClick={(e) => {
          handleSubmitExcel(electives);
        }}
        size="md"
        disabled={loading}
      >
        {loading ? 'Loading..' : 'Export CSV'}
      </Button>
    </div>
  );
};

export default ExportElectives;
