
import { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import api from "api";
import { Store, UpdateStore } from "StoreContext";

const Header = () => {
  const { counts,schools ,hospitals} = Store();
  const updateStore = UpdateStore();
  

   useEffect(() => {
    getCounts();
  }, [schools,hospitals]);

   const getCounts = async () => {
    const data = await api("get", "/v1/hospitals/countIs");
    console.log("count daat",data)
    updateStore({ counts: data.count });
  };
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"> 
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
                <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Users
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{counts?.studentsCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Hospitals
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {counts?.hospitalsCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Schools
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{counts?.schoolsCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  
                  </CardBody>
                </Card>
              </Col>
            
             
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
