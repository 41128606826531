import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../api';
// import { Select } from "antd";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from 'reactstrap';
// core components
import Select from 'react-select';
import hidepassword from '../../assets/img/theme/hidepass.svg';

const { Option } = Select;

const ResetPassword = ({ openModal, handleModal }) => {
  const [logo, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  let [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [password1, setPassword1] = useState(false);
  const [password2, setPassword2] = useState(false);
  const [password3, setPassword3] = useState(false);

  const handleInput = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.oldPassword == '') {
      return toast.error('Please enter old passowrd');
    }
    if (password.newPassword == '') {
      return toast.error('Please enter new passowrd');
    }
    if (password.confirmPassword == '') {
      return toast.error('Please enter confirm passowrd');
    }
    if (password.confirmPassword != password.newPassword) {
      return toast.error('Password does not match');
    }

    console.log('paassss', password);
    api('put', '/v1/admins/resetPassword', {
      oldPassword: password.oldPassword,
      newPassword: password.newPassword
    }).then((res) => {
      toast.success('Password updated successfully');

      handleModal();
    });
  };

  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        {/* <ModalHeader charCode="X" toggle={handleModal} style={{ fontWeight: 'bold', padding: '16px' }}>
          
        </ModalHeader> */}
        <h2 style={{ textAlign: 'center', padding: '20px 0px' }}>
          Reset Password
        </h2>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {' '}
                          <span style={{ color: 'red' }}>
                            {password.oldPassword ? '' : '*'}{' '}
                          </span>
                          Old Password
                        </label>{' '}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Old password here"
                          type={password1 ? 'text' : 'password'}
                          value={password.oldPassword}
                          name="oldPassword"
                          onChange={handleInput}
                        />
                        <img
                          src={hidepassword}
                          style={{
                            position: 'relative',
                            left: '90%',
                            bottom: '35px'
                          }}
                          onClick={() => {
                            setPassword1(!password1);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {' '}
                          <span style={{ color: 'red' }}>
                            {password.newPassword ? '' : '*'}{' '}
                          </span>
                          New Password
                        </label>{' '}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter new password here"
                          type={password2 ? 'text' : 'password'}
                          value={password.newPassword}
                          name="newPassword"
                          onChange={handleInput}
                        />
                        <img
                          src={hidepassword}
                          style={{
                            position: 'relative',
                            left: '90%',
                            bottom: '35px'
                          }}
                          onClick={() => {
                            setPassword2(!password2);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {' '}
                          <span style={{ color: 'red' }}>
                            {password.confirmPassword ? '' : '*'}{' '}
                          </span>
                          Confirm Password
                        </label>{' '}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter confirm password here"
                          type={password3 ? 'text' : 'password'}
                          value={password.confirmPassword}
                          name="confirmPassword"
                          onChange={handleInput}
                        />
                        <img
                          src={hidepassword}
                          style={{
                            position: 'relative',
                            left: '90%',
                            bottom: '35px'
                          }}
                          onClick={() => {
                            setPassword3(!password3);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Reset
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ResetPassword;
