import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../api';
// import { Select } from "antd";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from 'reactstrap';
// core components
import Select from 'react-select';

const { Option } = Select;

const AddHospital = ({ openModal, handleModal, getHospitals }) => {
  const [logo, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  let [hospital, setHospital] = useState({
    name: '',
    email: '',
    password: '',
    address: '',
    logo: null
  });

  // const handleImageChange = (e) => {
  //   setHospital({ ...hospital, [e.target.name]: e.target.files[0] });
  // };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setHospital({ ...hospital, logo: selectedImage });

    if (selectedImage) {
      setPreviewImage(URL.createObjectURL(selectedImage));
    }

    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  const handleInput = (e) => {
    setHospital({ ...hospital, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hospital.email == '') {
      return toast.error('Please enter email');
    }
    if (hospital.logo == null) {
      return toast.error('Please Add Logo');
    }
    if (!(hospital.email.includes('.') && hospital.email.includes('@'))) {
      return toast.error('Please enter a valid email');
    }
    if (hospital.password == '') {
      return toast.error('Password is required');
    }
    if (hospital.password.length < 8) {
      return toast.error('Passwords should be of atleast 8 letters');
    }
    if (hospital.address == '') {
      return toast.error('Address is required');
    }

    const formData = new FormData();
    console.log('add hospital', hospital);

    Object.entries(hospital).forEach(([key, value]) => {
      formData.append(key, value);
    });

    for (let [key, value] of formData.entries()) {
      console.log('1212', key, value);
    }

    api('post', '/v1/hospitals', formData).then((res) => {
      toast.success('Hospital added successfully');
      getHospitals();
      handleModal();
    });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Add a hospital
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-logo"
                        style={{ marginLeft: '2%' }}
                      >
                        Hospital Logo
                        {/* <i className="ni ni-album-2" /> */}
                      </label>
                      <input
                        className="form-control-file form"
                        id="input-logo"
                        type="file"
                        onChange={handleImageChange}
                        style={{ marginLeft: '2%' }}
                      />
                      <div className="previewSelectedImg">
                        {previewImage && (
                          <img
                            style={{
                              width: '15%',
                              height: '15%',
                              marginTop: '1rem',
                              marginLeft: '2%'
                            }}
                            src={previewImage}
                            alt="Preview"
                          />
                        )}
                      </div>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {' '}
                          <span style={{ color: 'red' }}>
                            {hospital.name ? '' : '*'}{' '}
                          </span>
                          Name
                        </label>{' '}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's name here"
                          type="text"
                          value={hospital.name}
                          name="name"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {' '}
                          <span style={{ color: 'red' }}>
                            {hospital.email ? '' : '*'}{' '}
                          </span>
                          Email
                        </label>{' '}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's email here"
                          type="email"
                          value={hospital.email}
                          name="email"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-password"
                        >
                          <span style={{ color: 'red' }}>
                            {hospital.password ? '' : '*'}{' '}
                          </span>
                          Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-password"
                          placeholder="Password"
                          type="password"
                          value={hospital.password}
                          name="password"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: 'auto' }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: 'red' }}>
                            {hospital.address ? '' : '*'}{' '}
                          </span>{' '}
                          Address
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter hospital's Address here"
                          type="text"
                          value={hospital.address}
                          name="address"
                          onChange={handleInput}
                          // maxLength="50"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddHospital;
